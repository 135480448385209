<template>
    <div class="ui-layout-container login-container" @keydown.enter="loginHandle">
        <video class="background-video" muted autoplay loop poster="~@/assets/login/background.jpg">
            <!--<source src="~@/assets/login/background.webm" type="video/webm" />
            <source src="~@/assets/login/background.mp4" type="video/mp4" />-->
        </video>

        <div class="login-container__form" v-if="showForm">
            <div class="login-container__head">
                <img src="~@/assets/login/logo.svg" />
                <h3 class="login-container__title">{{ $t('logo.system') }}</h3>
            </div>
            <div class="login-container__content">
                <div v-show="loginStyle==1" class="qrcode-form">
                    <h3 class="login-container__title2">用户登录</h3>
                    <el-form :model="loginData" ref="loginForm" :rules="loginRules">
                        <el-form-item prop="username">
                            <el-input placeholder="请输入手机号" v-model="loginData.username">
                                <template slot="prepend">
                                    <img src="~@/assets/login/icon-man.svg" class="form-icon" />
                                </template>
                            </el-input>
                            <template slot="error">
                                <div class="el-form-item__error">
                                    <img src="~@/assets/login/warning.svg" class="warning-icon" />
                                    请输入手机号！
                                </div>
                            </template>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input type="password" v-model="loginData.password" placeholder="请输入密码">
                                <template slot="prepend">
                                    <img src="~@/assets/login/icon-password.svg" class="form-icon" />
                                </template>
                            </el-input>
                            <template slot="error">
                                <div class="el-form-item__error">
                                    <img src="~@/assets/login/warning.svg" class="warning-icon" />
                                    请输入密码！
                                </div>
                            </template>
                        </el-form-item>
                        <div class="login-container__button">
                            <el-button type="primary" @click="loginHandle">登录</el-button>
                        </div>
                    </el-form>
                </div>
                <div v-show="loginStyle==2" class="qrcode-form">
                    <h3 class="login-container__title2">用户登录</h3>
                    <img class="qrcode" src="~@/assets/login/qrcode.jpg" />
                    <div class="scan-tip">
                        <img src="~@/assets/login/icon-scan.svg" />
                        <span>扫一扫登录</span>
                    </div>
                </div>
                <img class="login" :src="loginStyleUrl" @click="changeLoginStyle" />
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {getUUID} from '@/utils';
import axios from '@/client/axios';
import qrcodeImg from '@/assets/login/icon-qrcode.svg';
import computerImg from '@/assets/login/icon-computer.svg';
export default {
    name: 'Login',
    data() {
        return {
            loginStyleUrl: qrcodeImg,
            showForm: true,
            loginStyle: 1,
            activeName: '1',
            loginData: {
                username: '',
                password: '',
                captcha: '',
                uuid: ''
            },
            captchaPath: '',
            loginRules: {
                username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}]
            }
        };
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        ...mapActions(['doLogin']),
        redirectHandle(url) {
            window.location = url;
        },
        changeLoginStyle() {
            this.loginStyle = this.loginStyle === 1 ? 2 : 1;
            this.loginStyleUrl = this.loginStyle === 1 ? qrcodeImg : computerImg;
        },
        loginHandle() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.doLogin(this.loginData).then(() => {
                        this.$router.push('/');
                    });
                }
            });
        },
        getCaptcha() {
            this.loginData.uuid = getUUID();
            this.captchaPath = `${axios.defaults.baseURL}captcha.jpg?uuid=${this.loginData.uuid}`;
        }
    },
    created() {
        this.getCaptcha();
    }
};
</script>

<style lang="less">
.background-video {
    min-height: 100%;
    width: 100%;
    overflow-y: hidden;
    position: relative;
}
.login-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    &__menu {
        background: none !important;
        border-bottom: none !important;
        .el-menu-item {
            font-size: 16px;
            color: white !important;
            border-bottom: #00ffff solid 3px !important;
            padding: 5px 2px !important;
        }
        .el-menu-item:hover {
            background: none !important;
            color: white !important;
        }
        .el-menu-item .is-active {
            background: none !important;
            color: white !important;
        }
    }
    &__menu:hover {
        background: none !important;
    }
    &__head {
        display: flex;
        align-items: center;
        img {
            width: 60px;
            height: 60px;
        }
    }
    &__content {
        position: relative;
        margin-top: 50px;
        border: 1px solid #1360A4;
        background-color: rgba(16, 27, 57, 0.5);
        padding: 0 50px;
        height: 350px;
        width: 400px;
        margin-left: 50px;
        .form-icon {

        }
        @placeholderColor:#A7A9C7;
        input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: @placeholderColor;
        }
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color:@placeholderColor;
        }
        input::-moz-placeholder { /* Mozilla Firefox 19+ */
            color:@placeholderColor;
        }
        input:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color:@placeholderColor;
        }
        .el-input-group__prepend{
            background-color: #041744 !important;
            width: 40px !important;
            border: none !important;
            padding: 0 !important;
            text-align: center !important;
        }
        .el-form-item{
            margin-bottom: 40px !important;
        }
        .el-input--mini{
            border: #1484CE solid 1px !important;
            border-radius: 4px;
        }
        .el-input__inner{
            height: 40px !important;
            font-size: 14px !important;
            background-color: #112A68 !important;
            border: none !important;
            color: #EDEDFD;
        }
        .el-form-item__error{
            margin-left: 40px !important;
            margin-top: 5px !important;
            display: flex;
            justify-content: center;
            .warning-icon{
                width: 14px;
                height: 14px;
                margin-right: 2px;
            }
        }
        .login {
            position: absolute;
            top: 0;
            right: 0;
            width: 52px;
            height: 54px;
        }
        .qrcode-form {
            padding-top: 50px;
            text-align: center;
        }
        .scan-tip {
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: center;
            font-size: 14px;
            vertical-align: middle;
            color: #A7A9C7;
        }
        .scan-tip span{
            margin-left: 10px;
        }
        .qrcode {
            width: 190px;
            height: 190px;
            border: 2px solid #1484CE;
            border-radius: 4px;
        }
    }
    &__form {
        width: 620px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%);
        .el-input__inner {
            background: transparent;
            color: white;
        }
    }
    &__title {
        font-size: 40px;
        color: #eee;
        margin: 0 auto;
        text-align: center;
        font-weight: 700;
    }
    &__title2 {
        font-size: 18px;
        color: #eee;
        margin: 0 auto 20px;
        text-align: center;
        font-weight: 700;
    }
    &__button {
        .el-button--primary {
            width: 100%;
            height: 50px !important;
            background-color:#11539C !important;
            font-size: 18px;
            border-color: #17B4FF !important;
        }
    }
    &__social {
        color: white;
        text-align: right;
        & > span {
            font-size: 12px;
            padding: 10px;
            display: inline-block;
            &:hover {
                opacity: 0.8;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
.icon-svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    overflow: hidden;
}
</style>
